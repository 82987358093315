import React, { useState, useEffect } from 'react';
import { useAuth } from './../../AuthProvider';
import { Button, Grid, Typography, Box } from '@mui/material';
import { ReactComponent as MyAccountIconDark } from '@/../../public/myaccounticondark.svg';
import { ReactComponent as LogoutIconDark } from '@/../../public/logouticondark.svg';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Hamburger from '../Hamburger';

// Run Report Icon
import { ReactComponent as HomeIconLight } from '@/../../public/homeiconlight.svg';
import { ReactComponent as HomeIconDark } from '@/../../public/homeicondark.svg';

// Bulk Icon
import { ReactComponent as BulkIconDark } from '@/../../public/bulkicondark.svg';
import { ReactComponent as BulkIconLight } from '@/../../public/bulkiconlight.svg';

// Learn Icon
import { ReactComponent as LearnIcon } from '@/../../public/learnicondark.svg';

// Explore Icon
import { ReactComponent as ExploreIconDark } from '@/../../public/msaIconDark.svg';
import { ReactComponent as ExploreIconLight } from '@/../../public/msaIconLight.svg';

// DropDown Icon
import { ReactComponent as DropDown } from '@/../../public/dropdowndark.svg';
import HeaderImage from '../../components/VestmapViewer/pdf_img/vestmap_logo.png';

import { ReactComponent as AdminIconDark } from '@/../../public/adminIconDark.svg';
import { ReactComponent as AdminIconLight } from '@/../../public/adminIconLight.svg';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
  selected: {
    backgroundColor: '#39585C',
    color: '#FFFFFF',
  },

  unselected: {
    color: '#39585C',
  },

  itemBoxStyle: {
    display: 'flex',
    height: '1.1rem',
    padding: '1rem',
    alignItems: 'center',
    gap: '0.75rem',
    alignSelf: 'stretch',
    borderRadius: '0.75rem',
  },

  itemTextStyle: {
    fontFamily: '"Lato", sans-serif',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '150%',
    letterSpacing: '0.0125rem',
  },
};

const Header = ({ pageTitle, sideBarState, setTemplateID, templateID }) => {
  const {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken,
  } = useAuth();

  const [admin, setAdmin] = useState(false);
  const [templates, setTemplates] = useState([]);

  const location = useLocation();

  async function checkIfAdmin() {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/admin/check-admin`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ email: user.Email }),
      });

      const data = await response.json();

      if (data.isAdmin === 'true') setAdmin(true);
      else {
        const admin_emails = ['Clay@vestmap.com'];
        if (admin_emails.includes(user?.email)) {
          setAdmin(true);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }

  async function getTemplates() {
    try {
      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      if (!user) throw new Error('User not found in localstorage');

      const url =
        process.env.REACT_APP_NODE_URL + '/templates/' + user._id + '?option=2';
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      const data = await res.json();
      setTemplates(data);
      setTemplateID({
        _id: data[0]._id,
        name: data[0].templateName,
        theme: data[0].theme,
        rentFlag:
          data[0]?.sections.some(section => section.key === 'rent') || false,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (user) {
      checkIfAdmin();
      if (pageTitle === 'Run Reports') getTemplates();
    }
  }, [user]);

  const buttonStyle = {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
    textTransform: 'capitalize',
    color: '#212529',
    p: 0,
    mr: '30px',
    background: 'transparent',
    '&:hover': { background: 'transparent' },
  };

  const [isSideNav, setSideNav] = useState(false);

  useEffect(() => {
    if (isSideNav) {
      console.log('sideBarState', sideBarState);
      if (sideBarState) sideBarState(true);

      document.body.classList.add('no-scroll');
    } else {
      console.log('sideBarState', sideBarState);
      if (sideBarState) sideBarState(false);

      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSideNav]);

  return (
    <>
      <nav>
        <Box
          sx={{
            position: 'fixed',
            borderTop: '1px solid #E5E5E5',
            top: '5.8rem', //* Don't give this value in %
            // top: '7.3%',
            left: '0',
            height: '100%',
            backgroundColor: '#F9F9F9',
            zIndex: '1000',
            transition: 'all 0.4s ease',
            transform: isSideNav ? 'translateX(0)' : 'translateX(-100%)',

            '@media (min-width: 600px)': {
              display: 'none',
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: 'transparent',
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1.25rem',
              gap: '4rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '1rem',
                width: '13.625rem',
              }}
            >
              <Link
                to='/run-reports'
                style={{ width: '100%', marginBottom: 0 }}
              >
                <Box
                  sx={[
                    styles.itemBoxStyle,
                    location.pathname === '/run-reports'
                      ? styles.selected
                      : styles.unselected,
                  ]}
                >
                  {location.pathname === '/run-reports' ? (
                    <HomeIconLight
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                      }}
                    />
                  ) : (
                    <HomeIconDark
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                      }}
                    />
                  )}

                  <Typography
                    sx={[
                      styles.itemTextStyle,
                      {
                        color:
                          location.pathname === '/run-reports' ||
                          location.pathname === '/'
                            ? styles.selected.color
                            : styles.unselected.color,

                        fontWeight:
                          location.pathname === '/run-reports' ||
                          location.pathname === '/'
                            ? 800
                            : 400,
                      },
                    ]}
                  >
                    Run Reports
                  </Typography>
                </Box>
              </Link>

              {admin && (
                <Link
                  to='/admin-panel'
                  style={{ width: '100%', marginBottom: 0 }}
                >
                  <Box
                    sx={[
                      styles.itemBoxStyle,
                      location.pathname === '/admin-panel'
                        ? styles.selected
                        : styles.unselected,
                    ]}
                  >
                    {location.pathname === '/admin-panel' ? (
                      <AdminIconLight
                        style={{
                          width: '1.25rem',
                          height: '1.25rem',
                        }}
                      />
                    ) : (
                      <AdminIconDark
                        style={{
                          width: '1.25rem',
                          height: '1.25rem',
                        }}
                      />
                    )}

                    <Typography
                      sx={[
                        styles.itemTextStyle,
                        {
                          color:
                            location.pathname === '/admin-panel'
                              ? styles.selected.color
                              : styles.unselected.color,

                          fontWeight:
                            location.pathname === '/admin-panel' ? 800 : 400,
                        },
                      ]}
                    >
                      Admin Panel
                    </Typography>
                  </Box>
                </Link>
              )}

              {/* <Link
                to='/bulk-import'
                style={{ width: '100%', marginBottom: 0 }}
              >
                <Box
                  sx={[
                    styles.itemBoxStyle,

                    location.pathname === '/bulk-import'
                      ? styles.selected
                      : styles.unselected,
                  ]}
                >
                  {location.pathname === '/bulk-import' ? (
                    <BulkIconLight
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                      }}
                    />
                  ) : (
                    <BulkIconDark
                      style={{
                        width: '1.25rem',
                        height: '1.25rem',
                      }}
                    />
                  )}

                  <Typography
                    sx={[
                      styles.itemTextStyle,
                      {
                        color:
                          location.pathname === '/bulk-import'
                            ? styles.selected.color
                            : styles.unselected.color,

                        fontWeight:
                          location.pathname === '/bulk-import' ? 800 : 400,
                      },
                    ]}
                  >
                    Bulk Imports
                  </Typography>
                </Box>
              </Link> */}

              <NestedAccordion currentPage={location.pathname} />

              <Box
                sx={[
                  styles.itemBoxStyle,
                  styles.unselected,
                  {
                    cursor: 'pointer',
                  },
                ]}
                onClick={() => {
                  window.open(
                    'https://vestmap.notion.site/VestMap-Data-Dictionary-01d8ebd075af4bea9c7ad0cb6dff6698',
                    '_blank',
                  );
                }}
              >
                <LearnIcon
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />

                <Typography
                  sx={[
                    styles.itemTextStyle,
                    {
                      color: styles.unselected.color,
                    },
                  ]}
                >
                  Learn to Use VestMap
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </nav>
      <header
        style={{
          margin: '0',
        }}
      >
        <Box
          className='main_header--wrap'
          sx={{
            backgroundColor: {
              xs: '#F9F9F9',
              md: 'transparent',
            },

            paddingX: '1.1%',
          }}
        >
          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
              },

              backgroundColor: {
                xs: '#F9F9F9',
                md: 'transparent',
              },

              paddingX: '1rem',

              width: '100%',

              justifyContent: 'space-between',
              alignItems: 'center',
              visibility: { xs: 'visible', sm: 'hidden', md: 'hidden' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                width: '60%',
              }}
            >
              <button
                className='hamburger'
                onClick={() => setSideNav(!isSideNav)}
              >
                <Hamburger isOpen={isSideNav} headerFlag={true} />
              </button>

              <img
                src={HeaderImage}
                alt='Vestmap Logo'
                style={{ width: '65%', height: 'auto' }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '2rem',
                width: '30%',
              }}
            >
              <MyAccountIconDark
                style={{
                  width: '1.375rem',
                  height: '1.375rem',
                  cursor: 'pointer',
                }}
                onClick={openProfile}
              />
              <LogoutIconDark
                onClick={logout}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Box>
          </Box>

          <div className='main_header--navbar'>
            {!user && !isLoading && (
              <>
                <Button onClick={openLogin} sx={buttonStyle} variant='text'>
                  Login
                </Button>
                <Button onClick={openSignup} sx={buttonStyle} variant='text'>
                  Sign Up
                </Button>
              </>
            )}
            {user && (
              <Grid container spacing={0}>
                <Grid sm={12} md={6} item={true}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Lato',
                        fontSize: '1.5rem',
                        fontStyle: 'normal',
                        fontWeight: '800',
                        lineHeight: '130%',
                        color: '#39585C',
                      }}
                    >
                      {pageTitle}
                    </Typography>

                    {pageTitle === 'Run Reports' && (
                      <Select
                        value={templateID?._id}
                        onChange={e =>
                          setTemplateID({
                            _id: e.target.value,
                            name: templates.find(
                              template => template._id === e.target.value,
                            )?.templateName,
                            theme: templates.find(
                              template => template._id === e.target.value,
                            )?.theme,
                            rentFlag:
                              templates
                                .find(
                                  template => template._id === e.target.value,
                                )
                                ?.sections.some(
                                  section => section.key === 'rent',
                                ) || false,
                          })
                        }
                        sx={{
                          width: '35%',
                          borderRadius: '1rem',
                          fontWeight: '800',
                          height: '100%',
                          fontSize: '0.875rem',
                          '@media (min-width: 600px) and (max-width: 900px)': {
                            fontSize: '0.70rem',
                          },
                          '@media (min-width: 900px) and (max-width: 1200px)': {
                            fontSize: '0.60rem',
                          },
                        }}
                        labelId='templates'
                        placeholder='Default (DISCERN)'
                      >
                        {templates.map(template => (
                          <MenuItem key={template._id} value={template._id}>
                            <Box
                              sx={{
                                fontFamily: 'Lato',
                                fontSize: '1rem',
                                padding: '0.1rem 0.25rem 0.1rem 0.25rem',
                                borderRadius: '0.5rem',
                                textAlign: 'center',
                                ...(template?.theme && template.theme),
                              }}
                            >
                              {template.templateName}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Box>
                </Grid>
                <Grid
                  sm={12}
                  md={6}
                  item={true}
                  sx={{ textAlign: 'right' }}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Grid container spacing={0} justifyContent={'flex-end'}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        marginRight: '1rem',
                        ':hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={openProfile}
                    >
                      <MyAccountIconDark
                        style={{
                          width: '1.375rem',
                          height: '1.375rem',
                        }}
                      />
                      <Typography
                        sx={{
                          color: '#39585C',
                          fontFamily: 'Lato',
                          fontSize: '0.875rem',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: '150%',
                          letterSpacing: '0.0125rem',
                        }}
                      >
                        My Account
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        ':hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={logout}
                    >
                      <LogoutIconDark />
                      <Typography
                        sx={{
                          color: '#39585C',
                          fontFamily: 'Lato',
                          fontSize: '0.875rem',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: '150%',
                          letterSpacing: '0.0125rem',
                        }}
                      >
                        Logout
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </Box>
      </header>
    </>
  );
};

const NestedAccordion = ({ currentPage }) => (
  <div style={{ width: '100%' }}>
    <Accordion
      disableGutters
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: currentPage === '/zipfinder' ? '#FFFFFF' : '#39585C',
            }}
          />
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={[
          styles.itemBoxStyle,
          currentPage === '/zipfinder' ? styles.selected : styles.unselected,
        ]}
      >
        {currentPage === '/zipfinder' ? (
          <ExploreIconLight
            style={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        ) : (
          <ExploreIconDark
            style={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        )}
        <Typography
          sx={[
            styles.itemTextStyle,
            {
              color:
                currentPage === '/zipfinder'
                  ? styles.selected.color
                  : styles.unselected.color,
              marginLeft: '8%',

              fontWeight: currentPage === '/zipfinder' ? 800 : 400,
            },
          ]}
        >
          Explore Markets
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ borderLeft: '2px solid grey' }}>
          <Link to='/zipfinder'>
            <ListItem>
              <ListItemText
                disableTypography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: currentPage === '/zipfinder' ? 800 : 500,
                  lineHeight: '150%',
                  letterSpacing: '0.0125rem',
                  color: currentPage === '/zipfinder' ? '#39585C' : '#000000',
                  cursor: 'pointer',
                }}
                primary='ZipFinder'
              />
            </ListItem>
          </Link>
          {/* <ListItem>
            <ListItemText
              disableTypography
              sx={{
                fontFamily: 'Lato',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                lineHeight: '150%',
                letterSpacing: '0.0125rem',
                fontWeight: 700,
              }}
              primary='Other Source'
            />
          </ListItem> */}
        </List>
      </AccordionDetails>
    </Accordion>
  </div>
);

export default Header;
