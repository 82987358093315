import React, { useState, useEffect } from 'react';
import { Edit, Save } from '@mui/icons-material';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MapEditor from './MapEditor';
import RealTimeView from './RealTimeView';
import { useAuth } from '../../../AuthProvider';

export default function AdminPage() {
  const { getAccessToken } = useAuth();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [editableRow, setEditableRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [loginState, setLoginState] = useState(false);

  // for different sections of admin panel
  const [currentSection, setCurrentSection] = useState('users'); // users, maps, real-time-view

  useEffect(() => {
    if (localStorage) {
      const user = JSON.parse(localStorage?.getItem('mongodbUser'));

      if (user?.admin === true) {
        setLoginState(true);
        return;
      } else {
        const admin_emails = ['Clay@vestmap.com'];
        console.log('User email:', user?.email);
        if (admin_emails.includes(user?.email)) {
          setLoginState(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log('Fetching users');
    getUsers();
  }, [page]);

  useEffect(() => {
    console.log('Loading changed, ', loading);
  }, [loading]);

  async function getUsers() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/admin/get-users?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Data:', data);

      const users = data?.users?.map(user => {
        return {
          ...user,
          bulk_searches_remaining: user.bulk_searches_remaining || 0,
          pro_searches_remaining: user.pro_searches_remaining || 0,
        };
      });

      setSearchResult(users);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  async function searchUsers() {
    try {
      console.log('Searching users, ', search, page, pageSize);
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/admin/search-users?search=${search}&page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const users = data?.users?.map(user => {
        return {
          ...user,
          bulk_searches_remaining: user.bulk_searches_remaining || 0,
          pro_searches_remaining: user.pro_searches_remaining || 0,
        };
      });

      setSearchResult(users);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error searching users:', error);
    }
  }

  function handleSearchChange(event) {
    setSearch(event.target.value);
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setPage(1); // Reset page number when performing a new search
    searchUsers();
  }

  function handlePageChange(newPage) {
    setPage(newPage);
  }

  function handleEditClick(user) {
    setEditableRow(user._id);
    setEditedData({
      bulk_searches_remaining: user.bulk_searches_remaining,
      pro_searches_remaining: user.pro_searches_remaining,
    });
  }

  function handleSaveClick(userId) {
    if (
      editedData.bulk_searches_remaining < 0 ||
      isNaN(editedData.bulk_searches_remaining) ||
      editedData.pro_searches_remaining < 0 ||
      isNaN(editedData.pro_searches_remaining)
    ) {
      console.log('Invalid data');
      return;
    }

    const updatedUsers = searchResult.map(user => {
      if (user._id === userId) {
        return {
          ...user,
          bulk_searches_remaining: editedData.bulk_searches_remaining,
          pro_searches_remaining: editedData.pro_searches_remaining,
        };
      }
      return user;
    });

    setSearchResult(updatedUsers);

    fetch(`${process.env.REACT_APP_NODE_URL}/admin/update-user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        userID: userId,
        ...editedData,
      }),
    })
      .then(response => {
        if (response.ok) {
          console.log('User updated successfully');
        } else {
          console.error('Error updating user');
        }
      })
      .catch(error => console.error('Error updating user:', error));
    setEditableRow(null);
  }

  function handleInputChange(event, field) {
    setEditedData({
      ...editedData,
      [field]: event.target.value,
    });
  }

  useEffect(() => {
    console.log('Login state changed, ', loginState);
  }, [loginState]);

  const tableCellStyle = {
    color: '#fff',
    fontWeight: 'bold',
  };
  if (!loginState) return null;

  function handleViewAsUser(user) {
    const url = `${window.location.origin}/user-view/${user._id}`;
    window.open(url, '_blank');
  }

  return (
    <>
      {{
        maps: <MapEditor returnToHome={() => setCurrentSection('users')} />,
        'real-time-view': (
          <RealTimeView returnToHome={() => setCurrentSection('users')} />
        ),
      }[currentSection] || (
        <div style={{ padding: '2rem' }}>
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '2%',
              }}
            >
              <Typography
                sx={{
                  color: '#39585c',
                  fontSize: '1.5rem',
                  fontWeight: '700',
                }}
              >
                Admin Panel
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#39585c',
                    '&:hover': {
                      backgroundColor: '#39585c',
                    },
                    color: '#fff',
                  }}
                  onClick={() => setCurrentSection('real-time-view')}
                >
                  Real Time View
                </Button>

                <Button
                  sx={{
                    backgroundColor: '#39585c',
                    '&:hover': {
                      backgroundColor: '#39585c',
                    },
                    color: '#fff',
                  }}
                  onClick={() => setCurrentSection('maps')}
                >
                  Map Editor
                </Button>

                <Button
                  sx={{
                    backgroundColor: '#39585c',
                    '&:hover': {
                      backgroundColor: '#39585c',
                    },
                    color: '#fff',
                  }}
                >
                  <Link
                    to='/run-reports'
                    style={{ color: '#fff', textDecoration: 'none' }}
                  >
                    Back to dashboard
                  </Link>
                </Button>
              </Box>
            </Box>

            <form
              onSubmit={handleSearchSubmit}
              style={{
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextField
                id='standard-full-width'
                label='Search'
                style={{ marginRight: '10px', width: '300px' }}
                size='small'
                placeholder='Search'
                InputLabelProps={{
                  shrink: true,
                }}
                value={search}
                onChange={handleSearchChange}
              />
              <Button
                variant='contained'
                type='submit'
                sx={{
                  backgroundColor: '#39585c',
                  '&:hover': {
                    backgroundColor: '#39585c',
                  },
                }}
              >
                Search
              </Button>
            </form>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: '#39585c',
                      color: '#fff',
                    }}
                  >
                    <TableCell sx={tableCellStyle}>Name</TableCell>
                    <TableCell sx={tableCellStyle}>Email</TableCell>
                    <TableCell sx={tableCellStyle}>
                      Bulk Searches Remaining
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      Pro Searches Remaining
                    </TableCell>
                    <TableCell sx={tableCellStyle}>Edit</TableCell>
                    <TableCell sx={tableCellStyle}>View as User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <>
                      {Array.from({ length: 10 }, (_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    searchResult.map(user => (
                      <TableRow key={user._id}>
                        <TableCell>
                          {user.firstname} {user.lastname}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell
                          sx={{
                            border:
                              user.bulk_searches_remaining < 0 ||
                              isNaN(user.bulk_searches_remaining)
                                ? '1px solid red'
                                : '',
                          }}
                        >
                          {editableRow === user._id ? (
                            <TextField
                              value={editedData.bulk_searches_remaining}
                              size='small'
                              onChange={event =>
                                handleInputChange(
                                  event,
                                  'bulk_searches_remaining',
                                )
                              }
                            />
                          ) : (
                            user?.bulk_searches_remaining || 0
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            border:
                              user.pro_searches_remaining < 0 ||
                              isNaN(user.pro_searches_remaining)
                                ? '1px solid red'
                                : '',
                          }}
                        >
                          {editableRow === user._id ? (
                            <TextField
                              size='small'
                              value={editedData.pro_searches_remaining}
                              onChange={event =>
                                handleInputChange(
                                  event,
                                  'pro_searches_remaining',
                                )
                              }
                              sx={{
                                border:
                                  editedData.pro_searches_remaining < 0 ||
                                  isNaN(editedData.pro_searches_remaining)
                                    ? '1px solid red'
                                    : '1px solid #39585c',

                                borderRadius: '5px',
                              }}
                            />
                          ) : (
                            user?.pro_searches_remaining || 0
                          )}
                        </TableCell>
                        <TableCell>
                          {editableRow === user._id ? (
                            <Save
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleSaveClick(user._id)}
                            />
                          ) : (
                            <Edit
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(user)}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: '#39585c',
                              '&:hover': {
                                backgroundColor: '#39585c',
                              },
                              color: '#fff',
                            }}
                            onClick={() => {
                              handleViewAsUser(user);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Array.from(
                { length: totalPages > 5 ? 5 : totalPages },
                (_, index) => (
                  <Button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    disabled={page === index + 1}
                    style={{ marginRight: '5px' }}
                    sx={{
                      backgroundColor: page === index + 1 ? '#39585c' : '#fff',
                      color: page === index + 1 ? '#fff' : '#39585c',
                      '&:hover': {
                        backgroundColor: '#39585c',
                        color: '#fff',
                      },
                      '&:disabled': {
                        backgroundColor: '#39585c',
                        color: '#fff',
                      },
                    }}
                  >
                    {index + 1}
                  </Button>
                ),
              )}
              {totalPages > 5 && (
                <Button
                  onClick={() => handlePageChange(page + 1)}
                  sx={{
                    backgroundColor: '#39585c',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#39585c',
                      color: '#fff',
                    },
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
}
