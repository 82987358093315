import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'BOX';

const DraggableBox = ({ id, text, index, moveBox }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: item => {
      if (item.index !== index) {
        moveBox(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <div
      ref={node => ref(drop(node))}
      style={{
        padding: '15px',
        textAlign: 'center',
        backgroundColor: '#4caf50',
        color: 'white',
        border: '1px solid #3e8e41',
        cursor: 'grab',
        transition: 'transform 0.2s',
        marginBottom: '10px',
      }}
    >
      {text}
    </div>
  );
};

export default function TestGround() {
  const [boxes, setBoxes] = useState([
    { id: 1, text: 'Box 1' },
    { id: 2, text: 'Box 2' },
    { id: 3, text: 'Box 3' },
    { id: 4, text: 'Box 4' },
  ]);

  const moveBox = (fromIndex, toIndex) => {
    const updatedBoxes = [...boxes];
    const [movedBox] = updatedBoxes.splice(fromIndex, 1);
    updatedBoxes.splice(toIndex, 0, movedBox);
    setBoxes(updatedBoxes);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          width: '300px',
          margin: '20px auto',
          padding: '10px',
          border: '2px dashed #ccc',
          backgroundColor: '#f9f9f9',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>Draggable Boxes</h1>
        <p style={{ textAlign: 'center', color: '#555' }}>
          Drag the boxes to reorder them:
        </p>
        {boxes.map((box, index) => (
          <DraggableBox
            key={box.id}
            id={box.id}
            text={box.text}
            index={index}
            moveBox={moveBox}
          />
        ))}
      </div>
    </DndProvider>
  );
}
