/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';

import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import React, { useContext, useEffect, useRef, useState } from 'react';
import Hamburger from '../Hamburger';
import ErrorPopUp from '../pages/BulkVestmaps/ErrorPopUp';
import colors from '../VestmapViewer/Demographics/colors.json';
import data from '../VestmapViewer/Overlay/data.json';
import styles from './globalStyles.module.css';

import marker from './assets/marker center.png';

import { Star } from 'lucide-react';
import CustomVestmapContext from '../contexts/CustomVestmapContext';
import HeaderImage from '../VestmapViewer/pdf_img/vestmap_logo.png';

import { ReactComponent as GeneratePDFIconLight } from '@/../../public/pdfIconLight.svg';
import { ReactComponent as GenerateProReportIconLight } from '@/../../public/proReportIconLight.svg';

import { ReactComponent as DemoIconDark } from '@/../../public/demoIconDark.svg';
import { ReactComponent as DemoIconLight } from '@/../../public/demoIconLight.svg';

import { ReactComponent as IncomeIconDark } from '@/../../public/incomeIconDark.svg';
import { ReactComponent as IncomeIconLight } from '@/../../public/incomeIconLight.svg';

import { ReactComponent as HPIIconDark } from '@/../../public/hpiIconDark.svg';
import { ReactComponent as HPIIconLight } from '@/../../public/hpiIconLight.svg';

import { ReactComponent as SchoolsIconDark } from '@/../../public/schoolIconDark.svg';
import { ReactComponent as SchoolsIconLight } from '@/../../public/schoolIconLight.svg';

import { ReactComponent as CrimeIconDark } from '@/../../public/crimeIconDark.svg';
import { ReactComponent as CrimeIconLight } from '@/../../public/crimeIconLight.svg';

import { ReactComponent as ExpansionIconDark } from '@/../../public/expansionIconDark.svg';
import { ReactComponent as ExpansionIconLight } from '@/../../public/expansionIconLight.svg';

import { ReactComponent as RentIconDark } from '@/../../public/rentIconDark.svg';
import { ReactComponent as RentIconLight } from '@/../../public/rentIconLight.svg';

import { ReactComponent as NeighborhoodIconDark } from '@/../../public/neighborhoodIconDark.svg';
import { ReactComponent as NeighborhoodIconLight } from '@/../../public/neighborhoodIconLight.svg';

import { ReactComponent as MSAIconDark } from '@/../../public/msaIconDark.svg';
import { ReactComponent as MSAIconLight } from '@/../../public/msaIconLight.svg';

import { ReactComponent as AiAnalysisDark } from '@/../../public/flaskDark.svg';
import { ReactComponent as AiAnalysisLight } from '@/../../public/flaskLight.svg';
import { Share } from 'lucide-react';
import Overlay from '../VestmapViewer/Overlay';

import Groups from '../VestmapViewer/Demographics/groups.json';

import { loadModules } from 'esri-loader';

import { useAuth } from '../../AuthProvider';

const CustomVestmapViewer = ({
  open,
  setOpen,
  vestmapData,
  openSnackbar,
  modifyVestmap,
  viewerState = state => {},
  mapsLoading,
  setMapsLoading,
  arcgisToken,
  publicView = false,
}) => {
  const { getAccessToken } = useAuth();
  const [vestmap, setVestmap] = useState(vestmapData || {});
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [generatingPro, setGeneratingPro] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([]);
  const [accessToken, setAccessToken] = useState(arcgisToken);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  const [pdf, setPdf] = useState(
    vestmapData?.assets?.pdf || vestmapData?.pdf || null,
  );
  const [proReport, setProReport] = useState(
    vestmapData?.assets?.pro_report_path ||
      vestmapData?.pro_report_path ||
      null,
  );

  const [limitError, setLimitError] = useState(false);
  const [errorString, setErrorString] = useState('');

  const generatePro = async () => {
    if (generatingPro) return;
    setGeneratingPro(true);
    try {
      console.log('[Pro Report PDF Generation Started]');
      const url = process.env.REACT_APP_NODE_URL + `/report/request-pro-report`;

      const body = {
        userID: vestmap.MongodbUserID,
        address: vestmap.addressInfo.address,
        vestmapID: vestmap._id,
        bedrooms: +vestmap.bedrooms || 1,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      });

      if (response.status === 403) {
        setErrorString('Pro reports Limit Exceeded');
        setLimitError(true);
        return;
      } else if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        return;
      } else if (response.status === 203) {
        const data = await response.json();
        const { proReportPath } = data;

        setVestmap(prev => {
          const asset = prev?.assets || {};
          return {
            ...prev,
            assets: { ...asset, pro_report_path: proReportPath },
          };
        });

        setProReport(prev => {
          return proReportPath;
        });

        openSnackbar('success');
        return;
      }

      if (!response.ok)
        throw new Error('Something went wrong. Please try again.');

      const data = await response.json();
      const { proReportPath } = data;

      setVestmap(prev => {
        const asset = prev.assets || {};
        return {
          ...prev,
          assets: { ...asset, pro_report_path: proReportPath },
        };
      });

      setProReport(prev => {
        return proReportPath;
      });

      if (localStorage) {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        user.pro_searches_remaining -= 1;
        localStorage.setItem('mongodbUser', JSON.stringify(user));
      }

      openSnackbar('success');
    } catch (error) {
      console.log('Error happened here');
      setGeneratingPro(false);

      console.log({ error });
      openSnackbar('pro');
    } finally {
      setGeneratingPro(false);
    }
  };

  const viewPro = () => {
    if (generatingPro) return;

    window.open(proReport, '_blank');
  };

  const viewPDF = async () => {
    if (generatingPDF) return;

    if (pdf.includes('googleapis')) {
      window.open(pdf, '_blank');
      return;
    }

    const path = 'https://oldapp.vestmap.com/pdfs/' + pdf;
    window.open(path, '_blank');
  };

  const generatePDF = async () => {
    if (generatingPDF) return;
    setGeneratingPDF(true);

    try {
      console.log('[PDF Generation Started]');

      const url = process.env.REACT_APP_NODE_URL + `/pdf/request-pdf`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ vestmap_json: vestmap }),
      });

      if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        return;
      } else if (response.status === 203) {
        // 203 means it already exists
        const data = await response.json();
        const { link } = data;

        setVestmap(prev => {
          const asset = prev?.assets || {};
          return { ...prev, assets: { ...asset, pdf: link } };
        });

        setPdf(prev => {
          return link;
        });

        openSnackbar('success');
        return;
      }

      const data = await response.json();

      if (!data.status) {
        console.log('Error happened here');
        console.log({ data });
        throw new Error('Something went wrong. Please try again.');
      }
      console.log({ vestmap });

      setVestmap(prev => {
        const asset = prev?.assets || {};
        return { ...prev, assets: { ...asset, pdf: data?.link } };
      });

      setPdf(prev => {
        return data?.link;
      });

      openSnackbar('success');
    } catch (error) {
      console.log('Error happened here');
      setGeneratingPDF(false);

      console.log({ error });
      openSnackbar('pdf');
    } finally {
      setGeneratingPDF(false);
    }
  };

  useEffect(() => {
    if (pdf) {
      console.log('PDF is present');
      const asset = vestmap?.assets || {};
      if (!publicView) {
        modifyVestmap({ ...vestmap, assets: { ...asset, pdf } });
      }
    }
    if (proReport) {
      console.log('Pro Report is present');
      const asset = vestmap?.assets || {};
      if (!publicView) {
        modifyVestmap({
          ...vestmap,
          assets: { ...asset, pro_report_path: proReport },
        });
      }
    }
  }, [pdf, proReport]);

  useEffect(() => {
    setVestmap(vestmapData);
  }, [vestmapData]);

  useEffect(() => {
    console.log({ vestmapData });
    setPdf(vestmap?.assets?.pdf || vestmap?.pdf);
    setProReport(vestmap?.assets?.pro_report_path || vestmap?.pro_report_path);
    if (vestmapData?.templateName === 'Default (DISCERN)') {
      const orderedSections = [
        'demographics',
        'income',
        'hpi',
        'schools',
        'crime',
        'expansion',
        'rent',
        'neighborhood',
        'msa',
      ];

      vestmapData.sections.sort((a, b) => {
        return orderedSections.indexOf(a.key) - orderedSections.indexOf(b.key);
      });
    }
  }, []);

  useEffect(() => {
    setPdf(vestmap?.assets?.pdf || vestmap?.pdf);
    setProReport(vestmap?.assets?.pro_report_path || vestmap?.pro_report_path);
  }, [vestmap]);

  const handleClose = () => {
    if (pdf) modifyVestmap({ ...vestmap, assets: { ...vestmap.assets, pdf } });
    if (proReport)
      modifyVestmap({
        ...vestmap,
        assets: { ...vestmap.assets, pro_report_path: proReport },
      });

    // clear all states before closing
    setVestmap({});
    setGeneratingPDF(false);
    setGeneratingPro(false);

    setOpen(false);
    viewerState(false);
  };
  function closeLimitError() {
    setLimitError(false);
  }

  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {}, [sidebarOpen]);
  const [currentSection, setCurrentSection] = useState(null);

  useEffect(() => {
    setSidebarOpen(false);
  }, [currentSection]);

  return (
    <CustomVestmapContext.Provider
      value={{
        vestmap,
        setVestmap,
        modifyVestmap,
        generatePDF,
        viewPDF,
        generatePro,
        viewPro,
        generatingPDF,
        generatingPro,
        pdf,
        proReport,
        mapsLoading,
        setMapsLoading,
        sectionRefs,
        activeSection,
        setActiveSection,
        accessToken,
        setAccessToken,
        isOverlayVisible,
        setIsOverlayVisible,
        activeOverlay,
        setActiveOverlay,
        handleQuestionMarkClick,
        handleCloseOverlay,
        closeOutsideClick,
        updatedStyles,
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='body'
        fullWidth={true}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        className={styles.dialog}
        PaperProps={{
          sx: {
            boxSizing: 'border-box',
            borderRadius: {
              sm: '20px',
              xs: '0px',
            },
            overflow: {
              xs: sidebarOpen && 'hidden',
            },
          },
        }}
        fullScreen={true}
        id='viewerDialogBox'
        onClick={() => {
          if (sidebarOpen) {
            setSidebarOpen(false);
          }
        }}
      >
        {limitError && (
          <ErrorPopUp closeLimitError={closeLimitError} error={errorString} />
        )}
        <DialogContent className={styles.dialogContent}>
          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'grid',
              },
              gridTemplateColumns: {
                xs: '100%',
                sm: '22% 78%',
                md: '18% 82%',
              },
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                },
                position: 'sticky',
                top: 0,
                height: '100vh',
                overflowY: 'auto',
              }}
            >
              <Sidebar />
            </Box>

            {/* For smaller screen */}
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                },

                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 100,
                width: '60%',
                height: '100%',
                overflowY: 'auto',
                transition: 'transform 0.3s',
                transform: {
                  xs: sidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
                  sm: 'none',
                },
              }}
            >
              <Sidebar />
            </Box>

            <Box
              sx={{
                overflowY: {
                  sm: 'auto',
                  xs: 'none',
                },
                height: {
                  sm: '100vh',
                },

                scrollbarWidth: 'none',

                maxWidth: '100vw', // TODO
              }}
            >
              <DialogTitle
                id='scroll-dialog-title'
                className={styles.dialogHeader}
                sx={{
                  backgroundColor: '#F9F9F9',
                }}
              >
                {!publicView && (
                  <IconButton
                    aria-label='close'
                    onClick={setSidebarOpen.bind(this, !sidebarOpen)}
                    sx={{
                      position: 'absolute',
                      display: { xs: 'block', sm: 'none' },
                      left: 11,
                      top: 11,
                      height: '40px',
                      width: '40px',
                      zIndex: 1001,
                      color: theme => theme.palette.grey[500],
                    }}
                  >
                    <Hamburger isOpen={sidebarOpen} headerFlag={false} />
                  </IconButton>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '40px',
                    padding: '24px',
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        sm: '100%',
                        xs: '220px',
                      },
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={HeaderImage} alt='' />
                  </Box>
                  <div className={styles.dialogHeaderChild}>
                    <h2>
                      {vestmap?.address_info?.address ||
                        vestmap?.addressInfo?.address ||
                        ''}
                    </h2>
                  </div>
                </Box>

                {!publicView && (
                  <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                      position: 'fixed',
                      right: 11,
                      top: 11,
                      zIndex: 4,
                      height: '40px',
                      width: '40px',
                      color: theme => theme.palette.grey[500],
                    }}
                  >
                    <Hamburger isOpen={true} headerFlag={false} />
                  </IconButton>
                )}
              </DialogTitle>
              <VestmapAccordion
                vestmap={vestmap}
                modifyVestmap={modifyVestmap}
                mapsLoading={mapsLoading}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Box
            sx={{
              width: {
                sm: '79.7%',
                xs: '100%',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: {
                sm: '18px',
                xs: '14px',
              },

              gap: {
                sm: '16px',
                xs: '12px',
              },
            }}
          >
            <Box
              sx={{
                color: '#84939C',
                fontFamily: 'Lato',
                fontWeight: '700',
              }}
            >
              Custom Report Generated by
            </Box>
            <Box
              sx={{
                width: {
                  sm: '200px',
                  xs: '150px',
                },
                height: {
                  sm: '30px',
                  xs: '27px',
                },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={HeaderImage} alt='Vestmap Logo' />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </CustomVestmapContext.Provider>
  );
};

function VestmapAccordion({ vestmap, modifyVestmap }) {
  const { getAccessToken } = useAuth();
  const { accessToken, setAccessToken, sectionRefs, setActiveSection } =
    useContext(CustomVestmapContext);
  const [token, setToken] = useState(accessToken);
  const [loadingToken, setLoadingToken] = useState(false);

  async function getArcgisToken() {
    setLoadingToken(true);
    console.log({ accessToken });
    if (accessToken) {
      setToken(accessToken);
      setLoadingToken(false);
      return;
    }

    console.log('Token not found. Generating new...');

    try {
      const url = process.env.REACT_APP_NODE_URL + '/admin/token';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      if (data?.token) {
        setToken(data.token.access_token);
        setAccessToken(data.token.access_token);
        setLoadingToken(false);
        return;
      }

      throw new Error('Token not found');
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    } finally {
      setLoadingToken(false);
    }
  }

  useEffect(() => {
    getArcgisToken();
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null, // null makes it observe the entire viewport
      rootMargin: '0px',
      threshold: [0.5, 0.3, 0.2, 1], // 50% of the section must be visible
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.getAttribute('id'));
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      sectionRefs.current.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  useEffect(() => {
    console.log({ inner: vestmap });
  }, [vestmap]);

  const iconStyle = {
    height: '2.25rem',
    width: '2.25rem',
  };

  function renderBlock(block, blockIndex) {
    switch (block?.outfields?.[0]) {
      case 'nearest_3_schools':
        return (
          <NearestThreeSchoolsBlock schools={block?.value?.nearest_3_schools} />
        );
      case 'who_we_are':
        return <WhoWeAreBlock blockValue={block?.value?.who_we_are} />;

      case 'nearest_groups':
        return <NearestGroupsBlock groups={block?.value?.nearest_groups} />;

      case 'group':
        return <DemographicGroupBlock group={block?.value?.group} />;

      case 'median_household_income':
        return (
          <MedianHouseholdIncome
            blockValue={block?.value?.median_household_income}
          />
        );
      case 'median_home_value':
        return <MedianHomeValue blockValue={block?.value?.median_home_value} />;
      case 'annual_forecasted_median_income_growth':
        return (
          <AnnualForecastedMedianIncomeGrowth
            blockValue={block?.value?.annual_forecasted_median_income_growth}
          />
        );

      case 'price_appreciation_since_2011': {
        console.log({ block });

        return (
          <PriceAppreciation
            blockValue={block?.value?.price_appreciation_since_2011}
            title={'Price Appreciation Since 2011'}
            runningValues={
              block?.value?.price_appreciation_since_2011?.runningValues
            }
          />
        );
      }

      case 'background_rate_of_appreciation': {
        return (
          <PriceAppreciation
            blockValue={block?.value?.background_rate_of_appreciation}
            title={'Background Rate of Appreciation (1990 - present)'}
            runningValues={
              block?.value?.background_rate_of_appreciation?.runningValues
            }
          />
        );
      }

      case 'hpi_tract_zip_index': {
        console.log('here');
        const convertDataFormat = (zips, tracts, counties) => {
          if (!zips && !tracts && !counties) return null;
          const output = [];

          const years = Object.keys(zips || tracts || counties);
          years.forEach(key => {
            const year = key.split('_')[1];
            const zip = zips && zips[key];
            const tract = tracts && tracts[key];
            const county = counties && counties[key];
            output.push({
              name: year,
              zip: zip || null,
              tract: tract || null,
              county: county || null,
            });
          });
          return output;
        };

        const data = convertDataFormat(
          block?.value?.hpi_tract_zip_index?.zip,
          block?.value?.hpi_tract_zip_index?.tract,
          block?.value?.hpi_tract_zip_index?.county,
        );

        console.log({ data });

        return <ChartBlock data={data} />;
      }

      case 'block': {
        return (
          <ExpansionBlock
            sectionBlock={block}
            tooltipTitle={'Block Expansion'}
          />
        );
      }
      case 'tract': {
        return (
          <ExpansionBlock
            sectionBlock={block}
            tooltipTitle={'Tract Expansion'}
          />
        );
      }
      case 'zip': {
        return (
          <ExpansionBlock sectionBlock={block} tooltipTitle={'Zip Expansion'} />
        );
      }
      case 'county': {
        return (
          <ExpansionBlock
            sectionBlock={block}
            tooltipTitle={'County Expansion'}
          />
        );
      }
      case 'state': {
        return (
          <ExpansionBlock
            sectionBlock={block}
            tooltipTitle={'State Expansion'}
          />
        );
      }
      case 'national': {
        return (
          <ExpansionBlock
            sectionBlock={block}
            tooltipTitle={'National Expansion'}
          />
        );
      }

      case 'rent_block': {
        console.log({ rentBlock: block });
        return <RentBlock rentBlock={block} />;
      }

      case 'StoreName': {
        return <NearestStores stores={block} />;
      }

      case 'stat_block': {
        return (
          <StatsBlock
            address={block?.value?.stat_block?.NAME}
            stats={block?.value?.stat_block}
          />
        );
      }

      case 'business_diversification_index': {
        return (
          <DiversificationBlock
            title={block?.title}
            blockValue={
              block?.value?.business_diversification_index
                ?.MSA_NUM_BUSINESS_DIVERSIFICATION
            }
            tooltipTitle={'Business Diversification Index'}
          />
        );
      }

      case 'employers_diversification_index': {
        return (
          <DiversificationBlock
            title={block?.title}
            blockValue={
              block?.value?.employers_diversification_index
                ?.MSA_NUM_EMP_DIVERSIFICATION
            }
            tooltipTitle={'Employers Diversification Index'}
          />
        );
      }

      case 'top_businesses': {
        return (
          <Top5Block
            title={block?.title}
            blockValue={block?.value?.top_businesses}
          />
        );
      }

      case 'top_employers': {
        return (
          <Top5Block
            title={block?.title}
            blockValue={block?.value?.top_employers}
          />
        );
      }

      case 'overall_crime_index_score': {
        return <NationalAverageCrimeIndex sectionBlock={block} />;
      }

      case 'property_crime_index_score': {
        return <NationalAverageCrimeIndex sectionBlock={block} />;
      }

      default: {
        let tooltip = null;
        switch (block?.title) {
          case 'Grade': {
            tooltip = 'Grade';
            break;
          }
          default: {
            tooltip = null;
            break;
          }
        }
        return (
          <GenericBlock
            key={blockIndex}
            sectionBlock={block}
            tooltipTitle={tooltip}
          />
        );
      }
    }
  }

  function getIconForSection(sectionKey) {
    const icons = {
      demographics: <DemoIconDark style={iconStyle} />,
      income: <IncomeIconDark style={iconStyle} />,
      hpi: <HPIIconDark style={iconStyle} />,
      schools: <SchoolsIconDark style={iconStyle} />,
      crime: <CrimeIconDark style={iconStyle} />,
      expansion: <ExpansionIconDark style={iconStyle} />,
      rent: <RentIconDark style={iconStyle} />,
      neighborhood: <NeighborhoodIconDark style={iconStyle} />,
      msa: <MSAIconDark style={iconStyle} />,
      ai_analysis: <AiAnalysisDark style={iconStyle} />,
    };

    return icons[sectionKey];
  }

  if (!vestmap) return null;

  return (
    <Box sx={{ padding: { xs: '0' }, width: '100%' }}>
      {vestmap.sections.map((section, index) => (
        <Accordion
          key={index}
          defaultExpanded={true}
          slotProps={{ unmountOnExit: true }}
          sx={{
            borderRadius: '16px !important',
            boxShadow: '5px 20px 30px 0px #E5E5E5B2',
            scrollbarWidth: 'none',
            width: '100%',
          }}
          ref={el => (sectionRefs.current[index] = el)} // Assign the ref to the section
          id={section.key}
        >
          <AccordionSummary
            id={`panel${index}bh-header`}
            sx={{
              pointerEvents: 'none',
              display: 'flex',
              gap: '1rem',
              width: '100%',
            }}
          >
            {getIconForSection(section.key) && (
              <div style={{ marginRight: '1rem' }}>
                {getIconForSection(section.key)}
              </div>
            )}
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {section.title.includes('Income')
                ? 'Income'
                : section.title.includes('MSA')
                  ? 'Metropolitan Statistical Area'
                  : section.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {section?.webmapID !== '' && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                {loadingToken ? (
                  <Skeleton variant='rectangular' width='100%' height={480} />
                ) : (
                  token &&
                  (section?.mapBlock ? (
                    <LiveMap
                      webmapID={section.webmapID}
                      token={token}
                      selectedBlock={section.mapBlock}
                      defaultSettings={false}
                      addressInfo={vestmap?.addressInfo}
                    />
                  ) : (
                    <LiveMap
                      webmapID={section.webmapID}
                      token={token}
                      addressInfo={vestmap?.addressInfo}
                    />
                  ))
                )}
              </Box>
            )}

            {section?.key === 'crime' ? (
              <CrimeSection section={section} />
            ) : (
              <>
                {section?.key === 'msa' && (
                  <BlockNameHeading title={'Metropolitan Statistical Area'} />
                )}

                {section.dynamicBlocks
                  .reduce((rows, block, idx) => {
                    if (idx % 3 === 0) rows.push([]);
                    rows[rows.length - 1].push(block);
                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        flexDirection: { xs: 'column', sm: 'row' },
                      }}
                    >
                      {row.map((block, blockIndex) =>
                        renderBlock(block, blockIndex),
                      )}
                    </Box>
                  ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

function CrimeSection({ section }) {
  if (!section) return null;

  const renderBlock = (block, blockIndex) => {
    switch (block.outfields[0]) {
      case 'overall_crime_index_score': {
        return <NationalAverageCrimeIndex sectionBlock={block} />;
      }
      case 'property_crime_index_score': {
        return <NationalAverageCrimeIndex sectionBlock={block} />;
      }

      default: {
        return <GenericBlock key={blockIndex} sectionBlock={block} />;
      }
    }
  };

  const nationalAverageBlocks = section.dynamicBlocks.filter(block =>
    ['overall_crime_index_score', 'property_crime_index_score'].includes(
      block.outfields[0],
    ),
  );

  const crimeIndexBlocks = section.dynamicBlocks.filter(
    block =>
      !['overall_crime_index_score', 'property_crime_index_score'].includes(
        block.outfields[0],
      ),
  );

  return (
    <Box
      sx={{ padding: { xs: '0', sm: '0px 24px 20px 24px' }, marginTop: '1rem' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          marginBottom: '10px',
        }}
      >
        {nationalAverageBlocks.length > 0 && (
          <>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '1.4rem',
                fontWeight: 900,
                lineHeight: '20px',
                textAlign: 'center',
                color: '#39585C',
              }}
            >
              National Average Crime Index
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              {nationalAverageBlocks.map((block, blockIndex) =>
                renderBlock(block, blockIndex),
              )}
            </Box>
          </>
        )}
        {crimeIndexBlocks.length > 0 && (
          <>
            <Typography
              sx={{
                fontFamily: 'Lato',
                fontSize: '1.4rem',
                fontWeight: 900,
                lineHeight: '20px',
                textAlign: 'center',
                color: '#39585C',
              }}
            >
              Crime Index
            </Typography>

            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                paddingTop: '0.75rem',
                paddingBottom: '0.25rem',
                paddingX: '0',
              }}
            >
              <BlockNameHeading title={'Block Group'} />
              <BlockNameHeading title={'Block Group'} />
              <BlockNameHeading title={'Block Group'} />
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              {crimeIndexBlocks.map((block, blockIndex) => (
                <Box
                  key={blockIndex}
                  sx={{
                    maxWidth: {
                      xs: 'calc(50% - 10px)', // Adjust for gap.
                      sm: 'calc(33.33% - 10px)',
                    },

                    flexBasis: {
                      xs: 'calc(50% - 10px)', // Adjust for gap.
                      sm: 'calc(33.33% - 10px)',
                    },
                  }}
                >
                  {renderBlock(block, blockIndex)}
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

function BlockNameHeading({ title }) {
  return (
    <Typography
      sx={{
        fontFamily: 'Lato',
        fontSize: {
          xs: '1.1rem',
          sm: '1.4rem',
        },
        lineHeight: '20px',
        fontWeight: 900,
        textAlign: 'center',
        color: '#39585C',
      }}
    >
      {title}
    </Typography>
  );
}

const createResponsiveLegend = (Legend, view) => {
  // Add CSS to handle responsive legend
  const style = document.createElement('style');
  style.textContent = `
    .esri-legend {
      max-height: 150px !important; /* Smaller height on mobile */
      max-width: 150px !important;  /* Smaller width on mobile */
      overflow-y: auto !important;  /* Enable scrolling if content is too long */
      overflow-x: auto !important;
      background-color: rgba(255, 255, 255, 0.9) !important; /* Semi-transparent background */
    }

    @media screen and (min-width: 600px) {
      .esri-legend {
        max-height: 300px !important; /* Larger height on desktop */
        max-width: 250px !important;  /* Larger width on desktop */
      }
    }

    .esri-legend__service {
      padding: 8px !important;
    }
  `;
  document.head.appendChild(style);

  return new Legend({
    view: view,
  });
};

function LiveMap({
  webmapID,
  token,
  addressInfo,
  selectedBlock = null,
  defaultSettings = true,
}) {
  const mapRef = useRef(null);

  useEffect(() => {
    let view;
    loadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/FeatureLayer',
      'esri/widgets/Legend',
      'esri/config',
      'esri/smartMapping/statistics/classBreaks',
      'esri/WebMap',
      'esri/geometry/Extent',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
    ]).then(
      ([
        Map,
        MapView,
        FeatureLayer,
        Legend,
        esriConfig,
        classBreaks,
        WebMap,
        Extent,
        Graphic,
        GraphicsLayer,
      ]) => {
        if (!webmapID || !token) return;
        esriConfig.apiKey = token;

        let webmap = null;
        const extent = new Extent({
          xmin: addressInfo?.address_xmin,
          ymin: addressInfo?.address_ymin,
          xmax: addressInfo?.address_xmax,

          ymax: addressInfo?.address_ymax,
          spatialReference: {
            wkid: addressInfo?.spatialReference?.wkid,
          },
        });
        if (defaultSettings) {
          webmap = new WebMap({
            portalItem: {
              id: webmapID,
            },
          });

          view = new MapView({
            container: mapRef.current,
            map: webmap,
            extent: extent,
            zoom: 14,
            navigation: {
              mouseWheelZoomEnabled: false,
            },
          });

          view.ui.add(createResponsiveLegend(Legend, view), 'top-right');
          const customSymbol = {
            type: 'picture-marker',
            url: marker,
            width: '50px',
            height: '100px',
          };

          view.graphics.add(
            new Graphic({
              geometry: {
                type: 'point',
                longitude: addressInfo?.address_longitude,
                latitude: addressInfo?.address_latitude,
              },
              symbol: customSymbol,
            }),
          );
        } else {
          const defaultSym = {
            type: 'simple-fill',

            outline: {
              color: selectedBlock?.boundaries
                ? [57, 88, 92, 1]
                : [255, 255, 255, 0],

              width: '1px',
            },
          };

          const renderer = {
            type: 'simple',
            symbol: defaultSym,
            label: `U.S. ${selectedBlock.group}`,
            visualVariables: [
              {
                type: 'color',
                field: selectedBlock?.outfields[0],
                // normalizationField: "TOTPOP_CY",
                legendOptions: {
                  // title: "% population in poverty by county", // do smth abt the title
                },
              },
            ],
          };

          const customLayer = new FeatureLayer({
            url: selectedBlock.serviceURL,
            renderer: renderer,
            title: selectedBlock.title + ` (${selectedBlock.group})`,
            popupTemplate: {
              title: selectedBlock.title + ` (${selectedBlock.group})`,
              content: `{${selectedBlock?.outfields[0]}}`,
              fieldInfos: [
                {
                  fieldName: selectedBlock?.outfields[0],
                  format: {
                    digitSeparator: true,
                    places: 0,
                  },
                },
              ],
            },
          });

          customLayer.opacity = 0.6;
          customLayer.minScale = 0;
          customLayer.maxScale = 0;

          classBreaks({
            layer: customLayer,
            field: selectedBlock?.outfields[0],
            // normalizationField: "TOTPOP_CY",
            classificationMethod: 'quantile',
            // numClasses: 5,
          }).then(function (response) {
            let breakInfos = response.classBreakInfos;
            console.log({ breakInfos });

            // Create dynamic stops for the visual variable using the breakInfos
            const dynamicStops = breakInfos.map((info, index) => {
              return {
                value: info.maxValue, // Use maxValue from breakInfo
                color:
                  index === 0
                    ? '#a8e9c7' // Map your colors here
                    : index === 1
                      ? '#d9f0ae'
                      : index === 2
                        ? '#f9d58d'
                        : index === 3
                          ? '#f1a178'
                          : '#e66565', // You can expand the colors
                label: info.label, // Use the label from breakInfo
              };
            });

            // Update the renderer with dynamic stops
            customLayer.renderer = {
              type: 'simple',
              symbol: defaultSym,
              visualVariables: [
                {
                  type: 'color',
                  field: selectedBlock?.outfields[0],
                  // normalizationField: "TOTPOP_CY",
                  legendOptions: {
                    // title: "% population in poverty by county",
                  },
                  stops: dynamicStops, // Use the dynamically generated stops
                },
              ],
            };

            const map = new Map({
              basemap: 'gray-vector',
              layers: [customLayer],
            });

            view = new MapView({
              container: mapRef.current,
              map: map,
              extent: extent,
              navigation: {
                mouseWheelZoomEnabled: false,
              },
              scale: customLayer.minScale,
            });

            if (window.innerWidth <= 768) {
              console.log('Small screen');
            } else {
              console.log('big screen');
            }

            view.ui.add(
              new Legend({
                view: view,
                style: {
                  type: 'card',
                  layout: 'stack',
                },
              }),
              'top-right',
            );
            const customSymbol = {
              type: 'picture-marker',
              url: marker,
              width: '50px',
              height: '100px',
            };

            view.graphics.add(
              new Graphic({
                geometry: {
                  type: 'point',
                  longitude: addressInfo?.address_longitude,
                  latitude: addressInfo?.address_latitude,
                },
                symbol: customSymbol,
              }),
            );
          });
        }
      },
    );

    return () => {
      if (view) {
        view.destroy();
      }
    };
  }, [webmapID, token, selectedBlock, defaultSettings]);

  return (
    <Box
      ref={mapRef}
      sx={{
        width: '100%',

        height: {
          sm: '30rem',
          xs: '20rem',
        },
      }}
    ></Box>
  );
}

function Sidebar() {
  const {
    vestmap,
    sectionRefs,
    activeSection,
    pdf,
    proReport,
    viewPDF,
    viewPro,
    generatingPDF,
    generatingPro,
    accessToken,

    generatePDF,
    generatePro,
  } = useContext(CustomVestmapContext);

  const [selectedSection, setSelectedSection] = useState(
    vestmap.sections[0].key,
  );

  const styles = {
    selected: {
      backgroundColor: '#39585C',
      color: '#FFFFFF',
    },

    unselected: {
      color: '#39585C',
      fontWeight: 400,
      fontStyle: 'normal',
    },

    itemBoxStyle: {
      display: 'flex',
      height: '1.1rem',
      padding: '1rem',
      alignItems: 'center',
      gap: '0.75rem',
      alignSelf: 'stretch',
      borderRadius: '0.75rem',
    },

    itemTextStyle: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        xs: '0.875rem',
        sm: '.75rem',
        lg: '0.875rem',
      },
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '150%',
      letterSpacing: '0.0125rem',
    },

    upperBoxInnerStyle: {
      boxSizing: 'border-box',
      backgroundColor: '#4FA490',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.75rem',
      borderRadius: '1rem',
      padding: '0.75rem 0.2rem 0.75rem 0.2rem',
      cursor: 'pointer',
    },

    upperBoxTypography: {
      fontFamily: '"Lato", sans-serif',
      fontSize: {
        sm: '16px',
        xs: '0.8rem',
      },
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.2px',
      textAlign: 'center',
      color: '#FFFFFF',
    },

    selectedBox: {
      backgroundColor: '#39585C',
    },
    selectedText: {
      color: '#FFFFFF',
      fontWeight: 800,
      textAlign: 'left',

      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedText: {
      color: '#39585C',
      fontWeight: 400,
      textAlign: 'left',
      fontSize: {
        sm: '14px',
        xs: '0.8rem',
      },
    },
    unselectedBox: {
      backgroundColor: '#F9F9F9',
    },
    iconStyle: {
      width: '1.5rem',
      height: '1.5rem',
    },
    lowerBoxInnerStyle: {
      boxSizing: 'border-box',

      backgroundColor: '#4FA490',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      borderRadius: '1.2rem',
      padding: '16px',

      cursor: 'pointer',
    },
  };

  const handleClick = index => {
    console.log({ click: vestmap.sections[index].key });
    setSelectedSection(vestmap.sections[index].key);

    sectionRefs.current[index]?.scrollIntoView({});
  };

  useEffect(() => {
    if (activeSection) {
      setSelectedSection(activeSection);
    }
  }, [activeSection]);
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100vh',
        overflowY: 'auto',
        width: {
          sm: '19%',
          xs: '100%',
        },

        backgroundColor: '#F9F9F9',
        display: 'flex',
        gap: '2rem',
        flexDirection: 'column',
        alignItems: 'center',

        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          marginTop: '13.5rem',
          marginBottom: '5rem',
          width: '85%',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.8rem',
            width: '96%',
          }}
        >
          <ShareModal vestmapId={vestmap?._id} access_token={accessToken} />
        </Box>
        {vestmap?.templateName === 'Default (DISCERN)' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.8rem',
              width: '96%',
              marginBottom: '1.2rem',
            }}
          >
            <Box
              sx={styles.upperBoxInnerStyle}
              onClick={() => {
                if (!pdf) {
                  generatePDF();
                } else {
                  viewPDF();
                }
              }}
            >
              {generatingPDF ? (
                <CircularProgress
                  size={'1.5rem'}
                  thickness={4.6}
                  style={{ color: '#fff' }}
                />
              ) : (
                <GeneratePDFIconLight />
              )}
              <Typography sx={styles.upperBoxTypography}>
                {!pdf ? 'Generate PDF' : 'View PDF'}
              </Typography>
            </Box>

            <Box
              sx={styles.upperBoxInnerStyle}
              onClick={() => {
                if (!proReport) {
                  generatePro();
                } else {
                  viewPro();
                }
              }}
            >
              {generatingPro ? (
                <CircularProgress
                  size={'1.5rem'}
                  thickness={4.6}
                  style={{ color: '#fff' }}
                />
              ) : (
                <GenerateProReportIconLight />
              )}
              <Typography sx={styles.upperBoxTypography}>
                {!proReport ? 'Generate Pro Report' : 'View Pro Report'}
              </Typography>
            </Box>
          </Box>
        )}

        {/* More Green Box Group */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '96%',
            position: 'sticky',
            top: '0',
          }}
        >
          {vestmap.sections.map((section, index) => (
            <Box
              key={section.key}
              sx={[
                styles.lowerBoxInnerStyle,
                selectedSection === section.key
                  ? styles.selectedBox
                  : styles.unselectedBox,
              ]}
              onClick={() => handleClick(index)}
            >
              <Box
                sx={{
                  width: '1.5rem',
                  height: '1.5rem',
                }}
              >
                {
                  {
                    demographics:
                      selectedSection === section.key ? (
                        <DemoIconLight style={styles.iconStyle} />
                      ) : (
                        <DemoIconDark style={styles.iconStyle} />
                      ),
                    income:
                      selectedSection === section.key ? (
                        <IncomeIconLight style={styles.iconStyle} />
                      ) : (
                        <IncomeIconDark style={styles.iconStyle} />
                      ),
                    hpi:
                      selectedSection === section.key ? (
                        <HPIIconLight style={styles.iconStyle} />
                      ) : (
                        <HPIIconDark style={styles.iconStyle} />
                      ),
                    schools:
                      selectedSection === section.key ? (
                        <SchoolsIconLight style={styles.iconStyle} />
                      ) : (
                        <SchoolsIconDark style={styles.iconStyle} />
                      ),
                    crime:
                      selectedSection === section.key ? (
                        <CrimeIconLight style={styles.iconStyle} />
                      ) : (
                        <CrimeIconDark style={styles.iconStyle} />
                      ),
                    expansion:
                      selectedSection === section.key ? (
                        <ExpansionIconLight style={styles.iconStyle} />
                      ) : (
                        <ExpansionIconDark style={styles.iconStyle} />
                      ),
                    rent:
                      selectedSection === section.key ? (
                        <RentIconLight style={styles.iconStyle} />
                      ) : (
                        <RentIconDark style={styles.iconStyle} />
                      ),
                    neighborhood:
                      selectedSection === section.key ? (
                        <NeighborhoodIconLight style={styles.iconStyle} />
                      ) : (
                        <NeighborhoodIconDark style={styles.iconStyle} />
                      ),
                    msa:
                      selectedSection === section.key ? (
                        <MSAIconLight style={styles.iconStyle} />
                      ) : (
                        <MSAIconDark style={styles.iconStyle} />
                      ),
                    ai_analysis:
                      selectedSection === section.key ? (
                        <AiAnalysisLight style={styles.iconStyle} />
                      ) : (
                        <AiAnalysisDark style={styles.iconStyle} />
                      ),
                  }[section.key]
                }
              </Box>
              <Typography
                sx={
                  selectedSection === section.key
                    ? styles.selectedText
                    : styles.unselectedText
                }
              >
                {section.title.includes('Income')
                  ? 'Income'
                  : section.title.includes('MSA')
                    ? 'Metropolitan Statistical Area'
                    : section.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function GenericBlock({ sectionBlock, tooltipTitle }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  if (!sectionBlock) return null;
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: {
        xs: '0.9rem',
        sm: '1.5rem',
      },
      textAlign: 'center',
    },
  };

  const formatNumber = value => {
    if (isNaN(value)) return value; // Return if not a number
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  // for crime only
  const formatMultiple = value => {
    if (!value) return 'N/A';
    const value2 = value / 100;
    return `${value2}`;
  };

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const sectionInfoAvailable =
    sectionBlock?.key === 'crime'
      ? data[sectionBlock?.outfields[0]]
      : data[tooltipTitle];

  return (
    <Box
      sx={{
        flex: {
          xs: '1 1 100%',
          sm: 1,
        },
        backgroundColor: '#F9F9F9',
        paddingY: {
          xs: '0.3rem',
          sm: '1.25rem',
        },
        paddingX: {
          xs: '0.75rem',
          sm: '1rem',
        },
        borderRadius: '1rem',
        display: 'flex',
        gap: {
          xs: '0.5rem',
          sm: '1rem',
        },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: {
          xs: '6rem',
          sm: '8rem',
        },
        position: 'relative',
      }}
    >
      {!sectionBlock?.key?.includes('crime') && (
        <BlockNameHeading title={sectionBlock?.group || ''} />
      )}
      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '0.9rem',
              sm: '1.1rem',
            },
            fontWeight: 500,
            color: '#39585C',
          },
        ]}
      >
        {sectionBlock.title}
      </Typography>
      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '1rem',
              sm: '1.4rem',
            },
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.key === 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map(outfield => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';
                  return `${prefix}${isNaN(sectionBlock.value[outfield]) ? formatNumber(sectionBlock.value[outfield]) : formatMultiple(sectionBlock.value[outfield])}${suffix}`;
                })
                .filter(value => value !== 'N/A')
                .join(' ')
            : 'N/A')}

        {sectionBlock?.key !== 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map((outfield, index) => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';

                  return `${prefix}${formatNumber(sectionBlock.value[outfield])}${suffix}`;
                })
                .join(' ')
            : 'N/A')}
      </Typography>

      {sectionBlock?.key === 'crime' && (
        <Typography
          sx={{
            fontFamily: 'Lato',
            lineHeight: '1.5rem',
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 500,
          }}
        >
          National Average
        </Typography>
      )}

      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            console.log('I am clicked');
            handleQuestionMarkClick(sectionBlock?.title);
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {(activeOverlay === sectionBlock?.title ||
        activeOverlay === tooltipTitle) && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={
              sectionBlock?.key === 'crime'
                ? sectionBlock?.outfields[0]
                : tooltipTitle
            }
          />
        </div>
      )}

      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}

function QuestionMarkSVG() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      stroke='#4FA490'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='lucide lucide-circle-help'
    >
      <circle cx='12' cy='12' r='10' />
      <path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' />
      <path d='M12 17h.01' />
    </svg>
  );
}

function ShareModal({ vestmapId, access_token }) {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const shareableLink = `${window.location.origin}/report/${vestmapId}`;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(shareableLink);
    setSnackbarOpen(true);
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          boxSizing: 'border-box',
          backgroundColor: '#4FA490',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.75rem',
          borderRadius: '1rem',
          padding: '0.75rem 0.2rem 0.75rem 0.2rem',
          cursor: 'pointer',
        }}
      >
        <Share
          style={{ width: '1.5rem', height: '1.5rem', color: '#FFFFFF' }}
        />
        <Typography
          style={{
            fontFamily: '"Lato", sans-serif',
            fontSize: {
              sm: '16px',
              xs: '0.8rem',
            },
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.2px',
            textAlign: 'center',
            color: '#FFFFFF',
          }}
        >
          Share Report
        </Typography>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            // width: '30rem',
            width: {
              sm: '30rem',
              xs: '100%',
            },
          }}
        >
          Share Report
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={shareableLink}
            InputProps={{
              readOnly: true,
            }}
            sx={{
              textWrap: 'wrap',
            }}
            margin='normal'
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              color: '#4FA490',
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleCopy}
            variant='contained'
            sx={{
              backgroundColor: '#4FA490',
              color: '#FFFFFF',
            }}
          >
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message='Link copied to clipboard'
      />
    </>
  );
}

// Demographics
function DemographicGroupBlock({ group }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  // Check if section info is available in data.json
  const sectionInfoAvailable = data['Demographic Group'];

  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    groupTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };
  if (!group) return null;
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1rem',
        paddingX: '1.75rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '0.75rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '0.75rem',
        }}
      >
        <BlockNameHeading title='Demographic Group' />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Box
          sx={[
            style.colorBox,
            {
              backgroundColor: `#${colors[group] || '000000'}`,
            },
          ]}
        />
        <a target={'_blank'} href={Groups[group]} rel='noreferrer'>
          <Typography sx={style.groupTitle}>{group}</Typography>
        </a>
      </Box>

      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            console.log('I am clicked');
            handleQuestionMarkClick('Demographic Group');
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {activeOverlay === 'Demographic Group' && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={'Demographic Group'}
          />
        </div>
      )}
    </Box>
  );
}

function NearestGroupsBlock({ groups }) {
  if (!groups) return null;
  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    groupBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
    },
    groupTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <BlockNameHeading title='Nearest Groups' />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            maxHeight: '10rem',
            overflowY: 'auto',
          }}
        >
          {groups.map(group => {
            return (
              <Box sx={style.groupBox}>
                <Box
                  sx={[
                    style.colorBox,
                    {
                      backgroundColor: `#${colors[group] || '000000'}`,
                    },
                  ]}
                />

                <a target={'_blank'} href={Groups[group]} rel='noreferrer'>
                  <Typography sx={style.groupTitle}>{group}</Typography>
                </a>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

function WhoWeAreBlock({ blockValue }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  // Check if section info is available in data.json
  const sectionInfoAvailable = data['Who we are'];

  if (!blockValue) return null;
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1rem',
        paddingX: '1.75rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '0.75rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
        }}
      >
        <BlockNameHeading title={'Who We Are'} />
      </Box>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Lato',
            fontSize: '1rem',
            fontWeight: '400',
            textAlign: 'justify',
          }}
        >
          {blockValue}
        </Typography>
      </Box>

      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            console.log('I am clicked');
            handleQuestionMarkClick('Who we are');
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {activeOverlay === 'Who we are' && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={'Who we are'}
          />
        </div>
      )}
    </Box>
  );
}

// Income
function MedianHouseholdIncome({ blockValue }) {
  console.log({ blockValue });
  return (
    <Box
      sx={{
        flexBasis: '100%',
        width: '100%',
        gap: '1.5rem',
        paddingY: { xs: '0', sm: '1rem' },
        paddingX: { xs: '0', sm: '1.75rem' },
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BlockNameHeading title='Median Household Income' />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '1rem',
            width: '100%',
          }}
        >
          <GenericBlock
            sectionBlock={{
              title: 'Block',
              value: {
                block: blockValue?.block,
              },
              outfields: ['block'],
              prefix: '$',
            }}
            tooltipTitle={'Block'}
          />
          <GenericBlock
            sectionBlock={{
              title: 'Tract',
              value: {
                tract: blockValue?.tract,
              },
              outfields: ['tract'],
              prefix: '$',
            }}
            tooltipTitle={'Tract'}
          />
          <GenericBlock
            sectionBlock={{
              title: 'Zip',
              value: {
                zip: blockValue?.zip,
              },
              outfields: ['zip'],
              prefix: '$',
            }}
            tooltipTitle={'Zip'}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },

            gap: '1rem',
            width: '100%',
          }}
        >
          <GenericBlock
            sectionBlock={{
              title: 'County',
              value: {
                county: blockValue?.county,
              },
              outfields: ['county'],
              prefix: '$',
            }}
            tooltipTitle={'County'}
          />
          <GenericBlock
            sectionBlock={{
              title: 'State',
              value: {
                state: blockValue?.state,
              },
              prefix: '$',
              outfields: ['state'],
            }}
            tooltipTitle={'State'}
          />
          <GenericBlock
            sectionBlock={{
              title: 'National',
              value: {
                national: blockValue?.national,
              },
              prefix: '$',
              outfields: ['national'],
            }}
            tooltipTitle={'National'}
          />
        </Box>
      </Box>
    </Box>
  );
}

function MedianHomeValue({ blockValue }) {
  return (
    <Box
      sx={{
        flexBasis: '100%',
        width: '100%',
        gap: '1.5rem',

        paddingY: { xs: '0', sm: '1rem' },
        paddingX: { xs: '0', sm: '1.75rem' },
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BlockNameHeading title='Median Home Value' />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },

          gap: '1rem',
          width: '100%',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: 'Block',
            value: {
              block: blockValue?.block,
            },
            outfields: ['block'],
            prefix: '$',
          }}
          tooltipTitle={'MHVB'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Tract',
            value: {
              tract: blockValue?.tract,
            },
            outfields: ['tract'],
            prefix: '$',
          }}
          tooltipTitle={'MHVT'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Zip',
            value: {
              zip: blockValue?.zip,
            },
            outfields: ['zip'],
            prefix: '$',
          }}
          tooltipTitle={'MHVZ'}
        />
      </Box>
    </Box>
  );
}

function AnnualForecastedMedianIncomeGrowth({ blockValue }) {
  return (
    <Box
      sx={{
        flexBasis: '100%',
        width: '100%',
        paddingY: '1rem',
        gap: '1.5rem',
        paddingX: { xs: '0', sm: '1.75rem' },
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BlockNameHeading title='Annual Forecasted Median Income Growth' />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },

          gap: '1rem',
          width: '100%',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: 'Tract',
            value: {
              tract: blockValue?.tract,
            },
            outfields: ['tract'],
            suffix: '%',
          }}
          tooltipTitle={'AFMI'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Zip',
            value: {
              zip: blockValue?.zip,
            },
            outfields: ['zip'],
            suffix: '%',
          }}
          tooltipTitle={'AFMI2'}
        />
      </Box>
    </Box>
  );
}

// Schools
function NearestThreeSchoolsBlock({ schools }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  // Check if section info is available in data.json
  const sectionInfoAvailable = data['Nearest 3 Schools'];

  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    schoolBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
    },
    schoolTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };

  const colors = ['#FF5733', '#33FF57', '#3357FF'];

  if (!schools) return null;
  console.log({ schools });

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          <BlockNameHeading title='Nearest Three Schools' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          {schools.map((school, index) => (
            <Box key={index} sx={style.schoolBox}>
              <Box
                sx={[
                  style.colorBox,
                  {
                    backgroundColor: colors[index] || '#000000',
                  },
                ]}
              />
              <Typography sx={style.schoolTitle}>
                {school.name}
                {school?.ranking && school.ranking?.rankStars && (
                  <>
                    {' ('}
                    {[...Array(school.ranking.rankStars)].map((_, i) => (
                      <Star key={i} size={16} fill='#FFD700' stroke='#FFD700' />
                    ))}
                    {')'}
                  </>
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            handleQuestionMarkClick('Nearest 3 Schools');
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {activeOverlay === 'Nearest 3 Schools' && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={'Nearest 3 Schools'}
          />
        </div>
      )}
    </Box>
  );
}

// HPI

function HpiHeader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        paddingY: { xs: '0', sm: '1rem' },
        paddingX: { xs: '0', sm: '1.75rem' },
      }}
    >
      <BlockNameHeading title={'Tract'} />
      <BlockNameHeading title={'Zip'} />
      <BlockNameHeading title={'County'} />
    </Box>
  );
}

function PriceAppreciation({ blockValue, title, runningValues }) {
  // Check if section info is available in data.json
  // const sectionInfoAvailable = data['Demographic Group'];

  // * For Background Rate of Appreciation (1990 - present)
  // Calculate the year over year appreciation
  const calculateAppreciation = data => {
    if (!data) return null;
    const parsedData = {};

    Object.entries(data).filter(([key, value]) => {
      if (value === 0) return false;
      const year = key.split('_')[1];
      return (parsedData[year] = value);
    });

    const years = Object.keys(parsedData).sort((a, b) => b - a);

    const latestYear = years[0];
    const latestHPI = parsedData[latestYear];
    const firstYear = years[years.length - 1];
    const firstHPI = parsedData[firstYear];

    const numberOfYears = latestYear - firstYear;

    // Formula for appreciation
    const appreciation =
      ((latestHPI - firstHPI) / (numberOfYears * firstHPI)) * 100;

    return { value: appreciation.toFixed(2), since: firstYear };
  };

  // * For Price Appreciation Since 2011
  // Calculate the % change from the first year to 2011
  const calculateChange = data => {
    console.log('Calculate Change called');
    if (!data) return null;
    // data is an object
    const parsedData = {};
    console.log({ data });
    Object.entries(data).filter(([key, value]) => {
      if (value === 0) return false;
      const year = key.split('_')[1];
      if (parseInt(year) < 2011) return false;
      return (parsedData[year] = value);
    });

    const years = Object.keys(parsedData).sort((a, b) => b - a);

    const latestYear = years[0];
    const latestHPI = parsedData[latestYear];
    const firstYear = years[years.length - 1];
    const firstHPI = parsedData[firstYear];

    const change = (latestHPI - firstHPI) / firstHPI + 1;
    return { value: change.toFixed(2), since: firstYear };
  };

  console.log({ runningValues });

  return (
    <>
      {title === 'Price Appreciation Since 2011' && <HpiHeader />}
      <Box
        sx={{
          flexBasis: '100%',
          width: '100%',
          gap: { xs: '0', sm: '1.5rem' },
          paddingY: { xs: '0', sm: '1rem' },
          paddingX: { xs: '0', sm: '1.75rem' },
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: { xs: '0.5rem', sm: '1rem' },
            width: '100%',
          }}
        >
          <GenericBlock
            sectionBlock={{
              title: title,
              value: {
                tract: isNaN(
                  title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.tract)?.value
                    : calculateAppreciation(runningValues?.tract)?.value,
                )
                  ? 'Unknown'
                  : title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.tract)?.value
                    : calculateAppreciation(runningValues?.tract)?.value,
              },
              outfields: ['tract'],
              suffix: isNaN(
                title === 'Price Appreciation Since 2011'
                  ? calculateChange(runningValues?.tract)?.value
                  : calculateAppreciation(runningValues?.tract)?.value,
              )
                ? ''
                : title === 'Price Appreciation Since 2011'
                  ? 'x'
                  : '% per year',
            }}
            tooltipTitle={
              title === 'Price Appreciation Since 2011'
                ? 'PAS-Tract'
                : 'BRA-Tract'
            }
          />
          <GenericBlock
            sectionBlock={{
              title: title,
              value: {
                zip: isNaN(
                  title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.zip)?.value
                    : calculateAppreciation(runningValues?.zip)?.value,
                )
                  ? 'Unknown'
                  : title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.zip)?.value
                    : calculateAppreciation(runningValues?.zip)?.value,
              },
              outfields: ['zip'],
              suffix: isNaN(
                title === 'Price Appreciation Since 2011'
                  ? calculateChange(runningValues?.zip)?.value
                  : calculateAppreciation(runningValues?.zip)?.value,
              )
                ? ''
                : title === 'Price Appreciation Since 2011'
                  ? 'x'
                  : '% per year',
            }}
            tooltipTitle={
              title === 'Price Appreciation Since 2011' ? 'PAS-Zip' : 'BRA-Zip'
            }
          />
          <GenericBlock
            sectionBlock={{
              title: title,
              value: {
                county: isNaN(
                  title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.county)?.value
                    : calculateAppreciation(runningValues?.county)?.value,
                )
                  ? 'Unknown'
                  : title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.county)?.value
                    : calculateAppreciation(runningValues?.county)?.value,
              },
              outfields: ['county'],
              suffix: isNaN(
                title === 'Price Appreciation Since 2011'
                  ? calculateChange(runningValues?.county)?.value
                  : calculateAppreciation(runningValues?.county)?.value,
              )
                ? ''
                : title === 'Price Appreciation Since 2011'
                  ? 'x'
                  : '% per year',
            }}
            tooltipTitle={
              title === 'Price Appreciation Since 2011'
                ? 'PAS-County'
                : 'BRA-County'
            }
          />
        </Box>
      </Box>
    </>
  );
}

const HPIChart = ({ data }) => {
  if (!data) return null;

  const prioriProps = [
    {
      stroke: '#4f7d70',
      dot: { r: 5, fill: 'white', stroke: '#659f8f' },
    },
    {
      stroke: '#b1cec6',
      dot: { r: 0 },
    },
    {
      stroke: '#80ffaa',
      dot: { r: 0 },
    },
  ];
  const assignPrioris = data => {
    const output = [];
    // Check if zip is not all 0
    const zipStatus = data.some(
      item => item['zip'] !== 0 && !(item['zip'] === undefined),
    );
    // Check if county is not all 0
    const countyStatus = data.some(
      item => item['county'] !== 0 && !(item['county'] === undefined),
    );
    // Check if tract is not all 0
    const tractStatus = data.some(
      item => item['tract'] !== 0 && !(item['tract'] === undefined),
    );

    if (tractStatus)
      output.push(prioriProps[0], prioriProps[1], prioriProps[2]);
    else if (zipStatus)
      output.push(prioriProps[1], prioriProps[0], prioriProps[2]);
    else if (countyStatus)
      output.push(prioriProps[2], prioriProps[1], prioriProps[0]);
    else output.push([...prioriProps]);
    return output;
  };
  const [tractProps, zipProps, countyProps] = assignPrioris(data);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = payload[0]?.payload;
      return (
        <div>
          <p className='label'>{`Year ${label}`}</p>
          {value.tract && <p className='value'>{`Tract: ${value.tract}`}</p>}
          {value.zip && <p className='value'>{`Zip: ${value.zip}`}</p>}
          {value.county && <p className='value'>{`County: ${value.county}`}</p>}
        </div>
      );
    }

    return null;
  };
  const isMobile = window.innerWidth <= 768;

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' fontSize={14} />
        {isMobile ? null : (
          <YAxis>
            <Label angle={-90} fontSize={6} position='insideLeft' />
          </YAxis>
        )}
        <Tooltip content={<CustomTooltip />} />
        <Legend
          iconType='rect'
          iconSize={20}
          margin={(0, 0, 0, -10)}
          verticalAlign='bottom'
        />
        <Line
          key={2}
          dataKey='tract'
          name='Tract'
          strokeWidth={3}
          {...tractProps}
        />
        <Line key={1} dataKey='zip' name='Zip' strokeWidth={3} {...zipProps} />
        <Line
          key={3}
          dataKey='county'
          name='County'
          strokeWidth={3}
          {...countyProps}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

function ChartBlock({ data }) {
  return (
    <>
      <div
        style={{
          height: '27rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <h2>HPI TRACT/ZIP INDEX</h2>
        <div
          style={{
            height: '27rem',
            width: '100%',
          }}
        >
          <HPIChart data={data} />
        </div>
      </div>
      <div
        style={{
          fontSize: '.75rem',
          lineHeight: '1.1',
          marginTop: '1rem',
          marginBottom: '1rem',
          paddingY: { xs: '0', sm: '1rem' },
          paddingX: { xs: '0', sm: '1.75rem' },
        }}
      >
        <p style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
          <span
            style={{
              fontSize: '.75rem',
              lineHeight: '1.1',
              fontWeight: 'bold',
            }}
          >
            {' '}
            The Federal Housing Finance Agency (FHFA) House Price Index (HPI){' '}
          </span>
          is a weighted, repeat-sales index that measures the movement of house
          prices in the United States. The HPI is calculated with the average
          price changes in repeat sales or re-financings on the same properties.
          The FHFA HPI data set is built on tens of millions of sales and offers
          insights about price fluctuations at the national, census division,
          state, metro area, county, ZIP code, and census tract levels¹.
        </p>

        <p style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
          <span style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
            {' '}
            If an HPI value increased by 10% from 1990 to 1991{' '}
          </span>
          , it means that house prices increased by 10% during that period. The
          HPI base is 100: all subsequent years are a measurement of total
          appreciation since the base year. For example, an HPI value of 756
          taken in 2023 means that area has appreciated 656% (756-100=656%)
          since 1990 (1990 as the base year has a value of 100).
        </p>

        <p style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
          <span style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
            {' '}
            The background rate of appreciation is the expected annual
            appreciation{' '}
          </span>
          per year for a given given area. It is calculated by taking the total
          appreciation since the base year divided by the number of years.
        </p>
      </div>
    </>
  );
}

const formatNumber = value => {
  if (isNaN(value)) return value; // Return if not a number
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

// Expansion
function ExpansionBlock({ sectionBlock, tooltipTitle }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const sectionInfoAvailable =
    sectionBlock?.key === 'crime'
      ? data[sectionBlock?.outfields[0]]
      : data[tooltipTitle];

  if (!sectionBlock) return null;
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  };

  const growth = num => {
    if (num >= 1.44) {
      return 'Meteoric Growth';
    }
    if (num >= 0.73 && num <= 1.43) {
      return 'Growing';
    }
    if (num >= -0.25 && num <= 0.72) {
      return 'Stable';
    }
    if (num >= -1 && num <= -0.26) {
      return 'Declining';
    }
    if (num < -1) {
      return 'Steeply Declining';
    } else {
      return '';
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '8rem',
        position: 'relative',
      }}
    >
      <Typography
        sx={[
          styles.text,
          {
            fontWeight: 500,
            color: '#39585C',
            fontSize: {
              xs: '1rem',
              sm: '1.1rem',
            },
          },
        ]}
      >
        {sectionBlock.title}
      </Typography>
      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '1.2rem',
              sm: '1.4rem',
            },
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.value
          ? sectionBlock?.outfields
              .map(outfield => {
                if (!sectionBlock.value[outfield]) {
                  return 'N/A';
                }
                const prefix =
                  sectionBlock?.prefix === 'None'
                    ? ''
                    : sectionBlock?.prefix || '';
                const suffix =
                  sectionBlock?.suffix === 'None'
                    ? ''
                    : sectionBlock?.suffix ||
                      (typeof sectionBlock.value[outfield] === 'number'
                        ? '%'
                        : '');

                return `${prefix}${formatNumber(sectionBlock.value[outfield])}${suffix}`;
              })
              .join(' ')
          : 'N/A'}
      </Typography>
      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '0.8rem',
              sm: '1rem',
            },
            fontWeight: 400,
            color: '#39585C',
          },
        ]}
      >
        {growth(formatNumber(sectionBlock?.value[sectionBlock?.outfields[0]]))}
      </Typography>

      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            console.log('I am clicked in expansion');
            handleQuestionMarkClick(tooltipTitle);
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {activeOverlay === tooltipTitle && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={tooltipTitle}
          />
        </div>
      )}
      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}

function RentBlock({ rentBlock }) {
  if (!rentBlock) return null;

  const formatNumber = value => {
    if (isNaN(value)) return value; // Return if not a number
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <Box
      sx={{
        flex: 1,
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '8rem',
      }}
    >
      <BlockNameHeading title={rentBlock?.value['rent_grid_title']} />

      <Box
        sx={{
          fontSize: {
            xs: '0.9rem',
            sm: '1.3rem',
          },
          fontWeight: '700',
          lineHeight: '20px',
          textAlign: 'center',

          '@media (max-width: 600px)': {
            lineHeight: '1.1',
          },
        }}
      >
        {rentBlock?.value['rent_grid_desc']}
      </Box>
      <p>
        Rent from
        <a
          target={'_blank'}
          href={rentBlock?.value['rent_grid_quickview_url']}
          rel='noreferrer'
          style={{
            fontWeight: '500',
            color: '#4FA490',
          }}
        >
          {' '}
          Rent-O-Meter
        </a>
      </p>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: 'Average',
            value: {
              average: rentBlock?.value['average']
                ? formatNumber(rentBlock?.value['average'])
                : 'N/A',
            },
            outfields: ['average'],
            prefix: '$',
          }}
          tooltipTitle={'Rent Average'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Median',
            value: {
              median: rentBlock?.value['median']
                ? formatNumber(rentBlock?.value['median'])
                : 'N/A',
            },
            outfields: ['median'],
            prefix: '$',
          }}
          tooltipTitle={'Rent Median'}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: '25 ~ PERCENTILE',
            value: {
              percentile25: rentBlock?.value['percentile_25']
                ? formatNumber(rentBlock?.value['percentile_25'])
                : 'N/A',
            },
            outfields: ['percentile25'],
            prefix: '$',
          }}
          tooltipTitle={'Rent 25 Percentile'}
        />
        <GenericBlock
          sectionBlock={{
            title: '75 ~ PERCENTILE',
            value: {
              percentile75: rentBlock?.value['percentile_75']
                ? formatNumber(rentBlock?.value['percentile_75'])
                : 'N/A',
            },
            outfields: ['percentile75'],
            prefix: '$',
          }}
          tooltipTitle={'Rent 75 Percentile'}
        />
      </Box>
    </Box>
  );
}

function NearestStores({ stores }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  if (!stores) return null;

  const style = {
    storeBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    storeTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <BlockNameHeading title='Nearest Stores' />

          <Box
            onClick={() => {
              handleQuestionMarkClick('Nearest Stores');
              setIsOverlayVisible(true);
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <QuestionMarkSVG />
          </Box>

          {activeOverlay === 'Nearest Stores' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Nearest Stores'}
              />
            </div>
          )}
        </Box>

        {stores?.value?.StoreName.map((store, index) => (
          <Box key={index} sx={style.storeBox}>
            <Typography sx={style.storeTitle}>{store}</Typography>
            <Box
              onClick={() => {
                handleQuestionMarkClick(`Store ${index + 1}`);
                setIsOverlayVisible(true);
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              <QuestionMarkSVG />
            </Box>

            {activeOverlay === `Store ${index + 1}` && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={`Store ${index + 1}`}
                />
              </div>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

// msa
function StatsBlock({ address, stats }) {
  const styles = {
    container: {
      flex: '1 0 100%',

      paddingY: '1.25rem',
      paddingX: '1rem',
      borderRadius: '1rem',
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '8rem',
    },
    address: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    row: {
      display: 'flex',
      gap: '1rem',
      width: '100%',
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.address}>{address}</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr 1fr',
            sm: 'repeat(4, 1fr)',
          },
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: 'Housing Affordability Index',
            value: {
              index: stats?.MSA_HAI_CY,
            },
            outfields: ['index'],
          }}
          tooltipTitle={'Housing Affordability Index'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Wealth Index',
            value: {
              index: stats?.MSA_WLTHINDXCY,
            },
            outfields: ['index'],
          }}
          tooltipTitle={'Wealth Index'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Population Growth Rate',
            value: {
              rate: stats?.MSA_POPGRWCYFY,
            },
            outfields: ['rate'],
          }}
          tooltipTitle={'Population Growth Rate'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Total Population',
            value: {
              population: stats?.MSA_TOTPOP20,
            },
            outfields: ['population'],
          }}
          tooltipTitle={'Total Population'}
        />
      </Box>
    </Box>
  );
}

function DiversificationBlock({ title, blockValue, tooltipTitle }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const sectionInfoAvailable = data[tooltipTitle];

  const styles = {
    boxStyle: {
      borderBottom: '2px solid gray',
      display: 'flex',
      gap: '1rem',
      padding: '0.5rem',
    },
    typographyStyle: {
      fontFamily: 'Lato',
      fontSize: {
        xs: '0.9rem',
        sm: '1.2rem',
      },
      fontWeight: '600',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
        padding: '1.5rem',
        position: 'relative',
      }}
    >
      <Box sx={styles.boxStyle}>
        <Typography
          sx={{
            ...styles.typographyStyle,
          }}
        >
          {title}
        </Typography>

        {sectionInfoAvailable && (
          <Box
            onClick={() => {
              handleQuestionMarkClick(tooltipTitle);
              setIsOverlayVisible(true);
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <QuestionMarkSVG />
          </Box>
        )}

        {activeOverlay === tooltipTitle && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={tooltipTitle}
            />
          </div>
        )}
      </Box>
      <Box sx={styles.boxStyle}>
        <Typography
          sx={{
            ...styles.typographyStyle,
            fontWeight: '900',
          }}
        >
          {isNaN(blockValue) ? 'N/A' : blockValue}
        </Typography>
      </Box>
    </Box>
  );
}

function Top5Block({ title, blockValue }) {
  const styles = {
    typography: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: {
        xs: '0.8rem',
        sm: '1rem',
      },
    },
    value: {
      fontSize: {
        xs: '1.05rem',
        sm: '1.25rem',
      },
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
        padding: '1.5rem',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F9F9F9',
          display: 'flex',
          paddingX: '1.5rem',
          paddingY: '.5rem',
          borderRadius: '1.2rem',
        }}
      >
        <BlockNameHeading title={title} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '.5rem',
            paddingY: '.25rem',
            borderBottom: '2px solid gray',
          }}
        >
          <Typography sx={styles.typography}>
            {title.includes('Businesses')
              ? blockValue.MSA_NUM_BUSINESS_TXT
              : blockValue.MSA_NUM_EMP_TXT}
          </Typography>
          <Typography
            sx={{
              ...styles.typography,
              ...styles.value,
              fontWeight: '900',
            }}
          >
            {title.includes('Businesses')
              ? formatNumber(blockValue.MSA_N01_BUS)
              : formatNumber(blockValue.MSA_NUM_EMP)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '.5rem',
            paddingY: '.25rem',
            borderBottom: '2px solid gray',
          }}
        >
          <Typography sx={styles.typography}>
            {title.includes('Businesses')
              ? blockValue.MSA_NUM_BUSINESS_TXT_2
              : blockValue.MSA_NUM_EMP_TXT_2}
          </Typography>
          <Typography
            sx={{
              ...styles.typography,
              fontWeight: '900',

              ...styles.value,
            }}
          >
            {title.includes('Businesses')
              ? formatNumber(blockValue.MSA_N02_BUS)
              : formatNumber(blockValue.MSA_NUM_EMP_2)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '.5rem',
            paddingY: '.25rem',
            borderBottom: '2px solid gray',
          }}
        >
          <Typography sx={styles.typography}>
            {title.includes('Businesses')
              ? blockValue.MSA_NUM_BUSINESS_TXT_3
              : blockValue.MSA_NUM_EMP_TXT_3}
          </Typography>
          <Typography
            sx={{
              ...styles.typography,
              fontWeight: '900',

              ...styles.value,
            }}
          >
            {title.includes('Businesses')
              ? formatNumber(blockValue.MSA_N03_BUS)
              : formatNumber(blockValue.MSA_NUM_EMP_3)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '.5rem',
            paddingY: '.25rem',
            borderBottom: '2px solid gray',
          }}
        >
          <Typography sx={styles.typography}>
            {title.includes('Businesses')
              ? blockValue.MSA_NUM_BUSINESS_TXT_4
              : blockValue.MSA_NUM_EMP_TXT_4}
          </Typography>
          <Typography
            sx={{
              ...styles.typography,
              fontWeight: '900',

              ...styles.value,
            }}
          >
            {title.includes('Businesses')
              ? formatNumber(blockValue.MSA_N04_BUS)
              : formatNumber(blockValue.MSA_NUM_EMP_4)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '.5rem',
            paddingY: '.25rem',
            borderBottom: '2px solid gray',
          }}
        >
          <Typography sx={styles.typography}>
            {title.includes('Businesses')
              ? blockValue.MSA_NUM_BUSINESS_TXT_5
              : blockValue.MSA_NUM_EMP_TXT_5}
          </Typography>
          <Typography
            sx={{
              ...styles.typography,
              fontWeight: '900',

              ...styles.value,
            }}
          >
            {title.includes('Businesses')
              ? formatNumber(blockValue.MSA_N05_BUS)
              : formatNumber(blockValue.MSA_NUM_EMP_5)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function NationalAverageCrimeIndex({ sectionBlock }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  if (!sectionBlock) return null;
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  };

  const formatNumber = value => {
    if (isNaN(value)) return value; // Return if not a number
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  // for crime only
  const formatMultiple = value => {
    if (!value) return 'N/A';
    const value2 = value / 100;
    return `${value2}`;
  };
  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '8rem',
        position: 'relative',
      }}
    >
      <BlockNameHeading title={sectionBlock?.group || ''} />

      <Typography
        sx={[
          styles.text,
          {
            fontSize: '1.4rem',
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.key === 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map(outfield => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';
                  return `${prefix}${isNaN(sectionBlock.value[outfield]) ? formatNumber(sectionBlock.value[outfield]) : formatMultiple(sectionBlock.value[outfield])}${suffix}`;
                })
                .filter(value => value !== 'N/A')
                .join(' ')
            : 'N/A')}

        {sectionBlock?.key !== 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map((outfield, index) => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';

                  return `${prefix}${formatNumber(sectionBlock.value[outfield])}${suffix}`;
                })
                .join(' ')
            : 'N/A')}
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Lato',
          lineHeight: '1.5rem',
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: 500,
        }}
      >
        National Average
      </Typography>

      <Typography
        sx={[
          styles.text,
          {
            fontSize: '1.1rem',
            fontWeight: 500,
            color: '#39585C',
          },
        ]}
      >
        {sectionBlock.title}
      </Typography>

      <Box
        onClick={() => {
          console.log('I am clicked');
          handleQuestionMarkClick(sectionBlock?.title);
          setIsOverlayVisible(true);
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <QuestionMarkSVG />
      </Box>

      {activeOverlay === sectionBlock?.title && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={sectionBlock?.title}
          />
        </div>
      )}

      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}

export default CustomVestmapViewer;
